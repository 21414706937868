import React from 'react';
import ContactUrl from '../static/contact_us.jpg';

const ContactBlock = () => {
    return(
        <div style={{ backgroundColor: "#fff", borderTop: "2px solid #eee" }} className="d-none d-md-block">
            <div className="container text-center text-sm-left">
                <div className="contact-us m-0 py-5 d-flex justify-content-center justify-content-sm-between flex-column flex-sm-row">
                    <div className="left">
                        <h3 className="mb-2">Contact OPPO Support</h3>
                        <p className="mb-4">Contact OPPO Support Team to get more help</p>
                        <a href="https://www.oppo.com/au/contact/" className="button-white" target="_blank" rel="noopener noreferrer">Contact Us</a>
                    </div>
                    <div className="right mt-5 mt-sm-0">
                        <img src={ContactUrl} alt="contact_us.jpg" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactBlock;