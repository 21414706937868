import React from 'react';
import ReactDOM from 'react-dom';
import ContactBlock from './js/contact_block';
import './scss/bootstrap-theme-customise.scss';
import 'react-bootstrap';
import Head from './jsx/header';
import Foot from './jsx/footer'
import './scss/style.css';
import './scss/animate.css';
class App extends React.Component {

	render() {
		return (
			<React.Fragment>
				<Head />
				<div className="landing">
					<div className="home-wrap">
						<div className="home-inner"></div>
					</div>
				</div>
				<div className="content container-fluid text-center py-4 select-service">
				<h1 className="text-center py-5">Choose at your convenience</h1>
					<div className="row justify-content-center pt-3 pb-5">
						<a href="/walkin" className="col-sm-5 col-12 mb-4 mb-sm-0">
							<div className="select-item py-5">
								<span><i className="fal fa-clock fa-3x"></i></span>
								<h3 className="mt-3 mb-4">Walk-in Repair</h3>
								<p>Bring your device to Service Centre for repair</p>
							</div>
						</a>
						<a href="/sendin" className="col-sm-5 col-12">
							<div className="select-item py-5">
								<span><i className="fal fa-box fa-3x"></i></span>
								<h3 className="mt-3 mb-4">Send-in Repair</h3>
								<p>Post your device to Service Centre for repair</p>
							</div>
						</a>
					</div>
				</div>
				<ContactBlock/>
				<Foot />
			</React.Fragment>
		)
	}
}

ReactDOM.render(<App />, document.getElementById('root'));
